import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"

import Nav from "../components/nav"
import Layout from "../components/layout"
import { rhythm } from "../utils/typography"

class About extends React.Component {
    render() {

        return (
            <StaticQuery 
                query={aboutQuery}
                render={data => {
                    const { author } = data.site.siteMetadata
                    return (<Layout location={this.props.location}>
                        <Nav />
                        <div
                            style={{
                                display: `flex`,
                                flexDirection: `column`,
                                justifyContent: `center`,
                                alignItems: `center`,
                                marginBottom: rhythm(2.5),
                            }}
                        >
                            <div>
                                <Image
                                    fixed={data.avatar.childImageSharp.fixed}
                                    alt={author}
                                    style={{
                                        marginRight: rhythm(1 / 2),
                                        marginBottom: 0,
                                        minWidth: 200,
                                        borderRadius: `100%`,
                                    }}
                                    imgStyle={{
                                        borderRadius: `50%`,
                                    }}
                                />
                            </div>
                            <div
                                style={{
                                    textAlign: `left`,
                                }}>
                                <h3>Hi! My name's Akaash.</h3>
                                <p>
                                    I'm a software engineer, based out of Bangalore, India. I currently work at
                                    <a href={`https://airbase.com`} style={{ marginLeft: `4px` }}>Airbase
                                    </a>, as an Engineering Manager. I've been involved in building software products
                                    in the financial technology space throughout my career. This includes stints at
                                    <a href={`https://intuit.com`} style={{ marginLeft: `4px` }}>Intuit</a>,
                                    <a href={`https://target.com`} style={{ marginLeft: `4px` }}>Target</a>,
                                    and more recently at an Indian startup called
                                    <a href={`https://numberz.co`} style={{ marginLeft: `4px` }}>numberz</a>
                                    &nbsp;where I was leading the technology team. You can find my resume
                                    <a href={`https://registry.jsonresume.org/akaashanky`} style={{ marginLeft: `4px` }}>here</a>.
                                </p>
                                <p>This here website of mine is meant to be a contrived vehicle for self-improvement. 
                                    It's simultaneously an excuse to 
                                    <a style={{ marginLeft: `4px` }} href={`https://medium.com/thrive-global/start-journaling-54ea2edb104`}>journal</a>, 
                                    <a style={{ marginLeft: `4px` }} href={`https://fs.blog/2012/04/feynman-technique/`}>learn new things</a> and maybe even 
                                    <a style={{ marginLeft: `4px` }} href={`http://www.codusoperandi.com/posts/increasing-your-luck-surface-area`}>get luckier</a>.
                                </p>
                            </div>
                        </div>
                    </Layout>)
                }}
            />
        )
    }
}


const aboutQuery = graphql`
  query AboutQuery {
    avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
      childImageSharp {
        fixed(width: 200, height: 200) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    site {
      siteMetadata {
        author
        social {
          twitter
          linkedin
          github
        }
      }
    }
  }
`

export default About

